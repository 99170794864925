import { Component, Input, Output, EventEmitter } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { CommonModule } from '@angular/common';
import { MatExpansionModule } from '@angular/material/expansion';

@Component({
  selector: 'app-modify-diligence',
  standalone: true,
  imports: [
    FormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    MatButtonModule,
    MatCardModule,
    CommonModule,
    MatExpansionModule
  ],
  template: `
    <mat-card class="mx-auto p-6">
      <mat-card-header>
        <mat-card-title>Modify Diligence Report</mat-card-title>
      </mat-card-header>
      <mat-card-content>
        <form (ngSubmit)="onUpdateDiligence()" class="flex flex-col space-y-4">
          <mat-form-field appearance="outline">
            <mat-label>Phase</mat-label>
            <mat-select [(ngModel)]="diligence.phase" name="phase">
              <mat-option *ngFor="let i of [1, 2, 3, 4, 5, 6]" [value]="i">
                {{ i }}
              </mat-option>
            </mat-select>
          </mat-form-field>

          <mat-form-field appearance="outline">
            <mat-label>RepScore</mat-label>
            <input matInput type="number" min="0" [(ngModel)]="diligence.repScore" name="repScore">
          </mat-form-field>

          <mat-form-field appearance="outline">
            <mat-label>RepSummary</mat-label>
            <textarea matInput rows="3" [(ngModel)]="diligence.repSumm" name="repSumm"></textarea>
          </mat-form-field>

          <mat-form-field appearance="outline">
            <mat-label>Critical Issue Summary</mat-label>
            <textarea matInput rows="5" [(ngModel)]="diligence.critIssueSummary" name="critIssueSummary"></textarea>
          </mat-form-field>

          <mat-form-field appearance="outline">
            <mat-label>Critical Issue Details</mat-label>
            <textarea matInput rows="5" [(ngModel)]="diligence.critIssueDetails" name="critIssueDetails"></textarea>
          </mat-form-field>

          <div class="bg-white shadow overflow-hidden" *ngIf="diligence.sancIssueSummary?.summary">
          <mat-card-title>Sanction Summary</mat-card-title>


            <div class="flex space-x-2">
              <mat-form-field appearance="outline" class="w-1/2">
                <mat-label>Sanctioned Entity</mat-label>
                <input matInput rows="2" [(ngModel)]="diligence.sancIssueSummary.summary.sanctionedEntity" name="sanctionedEntity"/>
              </mat-form-field>

              <mat-form-field appearance="outline" class="w-1/2">
                <mat-label>Key Points</mat-label>
                <input matInput rows="2" [(ngModel)]="diligence.sancIssueSummary.summary.keyPoints" name="keyPoints"/>
              </mat-form-field>
            </div>

            <table class="min-w-full divide-y divide-gray-200">
              <thead class="bg-gray-50">
                <tr>
                  <th class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Entity</th>
                  <th class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Result</th>
                  <th class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Reason</th>
                </tr>
              </thead>
              <tbody class="bg-white divide-y divide-gray-200">
                <tr *ngFor="let entity of diligence.sancIssueSummary?.summary?.relationsWithSanctionedHostileAndOrMalevolentEntities; let i = index">
                  <td class="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">{{ entity.row }}</td>
                  <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                    <select [(ngModel)]="entity.result" [name]="'result' + i">
                      <option value="YES">YES</option>
                      <option value="NO">NO</option>
                    </select>
                  </td>
                  <td class="px-6 py-4 text-sm text-gray-500">
                    <input type="text" [(ngModel)]="entity.reason" [name]="'reason' + i">
                  </td>
                </tr>
              </tbody>
            </table>
            </div>

          <!-- Risk-S3 section -->
      <mat-expansion-panel *ngIf="diligence.sancIssueSummary?.['risk-s3']?.companies">
        <mat-expansion-panel-header>
          <mat-panel-title>
            Risk-S3 Information
          </mat-panel-title>
        </mat-expansion-panel-header>

        <!-- Companies -->
        <div *ngIf="diligence.sancIssueSummary?.['risk-s3']?.companies">
          <h3>Companies</h3>
          <div *ngFor="let company of diligence.sancIssueSummary?.['risk-s3'].companies; let i = index">
            <mat-form-field appearance="outline" class="w-full">
              <mat-label>Company {{ company.name }} Comment</mat-label>
              <textarea matInput rows="10" [(ngModel)]="company.comment" [name]="'companyCommentS3' + i"></textarea>
            </mat-form-field>
          </div>
        </div>

        <!-- Individuals -->
        <div *ngIf="diligence.sancIssueSummary['risk-s3']?.individuals?.length">
          <h3>Individuals</h3>
          <div *ngFor="let individual of diligence.sancIssueSummary['risk-s3'].individuals; let i = index">
            <mat-form-field appearance="outline" class="w-full">
              <mat-label>Individual {{ i + 1 }} Comment</mat-label>
              <textarea matInput rows="2" [(ngModel)]="individual.comment" [name]="'individualCommentS3' + i"></textarea>
            </mat-form-field>
          </div>
        </div>
      </mat-expansion-panel>

      <!-- Risk-S1 section -->
      <mat-expansion-panel *ngIf="diligence.sancIssueSummary?.['risk-s1']?.companies?.length">
        <mat-expansion-panel-header>
          <mat-panel-title>
            Risk-S1 Information
          </mat-panel-title>
        </mat-expansion-panel-header>

        <!-- Companies -->
        <div *ngIf="diligence.sancIssueSummary?.['risk-s1']?.companies?.length">
          <h3>Companies</h3>
          <div *ngFor="let company of diligence.sancIssueSummary?.['risk-s1'].companies; let i = index">
            <mat-form-field appearance="outline" class="w-full">
              <mat-label>Company {{ company.name }} Comment</mat-label>
              <textarea matInput rows="10" [(ngModel)]="company.comment" [name]="'companyCommentS1' + i"></textarea>
            </mat-form-field>
          </div>
        </div>

        <!-- Individuals -->
        <div *ngIf="diligence.sancIssueSummary['risk-s1']?.individuals?.length">
          <h3>Individuals</h3>
          <div *ngFor="let individual of diligence.sancIssueSummary['risk-s1'].individuals; let i = index">
            <mat-form-field appearance="outline" class="w-full">
              <mat-label>Individual {{ i + 1 }} Comment</mat-label>
              <textarea matInput rows="2" [(ngModel)]="individual.comment" [name]="'individualCommentS1' + i"></textarea>
            </mat-form-field>
          </div>
        </div>
      </mat-expansion-panel>

          <!-- New Bill of Lading section -->
          <mat-expansion-panel *ngIf="diligence.sancIssueSummary?.screening?.companies">
            <mat-expansion-panel-header>
              <mat-panel-title>
                Sanction Screening Information 
              </mat-panel-title>
            </mat-expansion-panel-header>

            <div *ngIf="diligence.sancIssueSummary?.screening?.companies">
              <h3>Companies</h3>
              <div *ngFor="let company of diligence.sancIssueSummary.screening.companies; let i = index">
                <mat-card class="mb-4">
                  <mat-card-header>
                    <mat-card-title>{{ company.comment.target.name }}</mat-card-title>
                  </mat-card-header>
                  <mat-card-content>
                    <mat-form-field appearance="outline" class="w-full">
                      <mat-label>Company Name</mat-label>
                      <input matInput [(ngModel)]="company.comment.target.name" [name]="'companyName' + i">
                    </mat-form-field>
                    <mat-form-field appearance="outline" class="w-full">
                      <mat-label>Country</mat-label>
                      <input matInput [(ngModel)]="company.comment.target.country" [name]="'companyCountry' + i">
                    </mat-form-field>
                    <mat-form-field appearance="outline" class="w-full">
                      <mat-label>Registration Number</mat-label>
                      <input matInput [(ngModel)]="company.comment.target.regNumber" [name]="'companyRegNumber' + i">
                    </mat-form-field>
                    <mat-form-field appearance="outline" class="w-full">
                      <mat-label>Legal Form</mat-label>
                      <input matInput [(ngModel)]="company.comment.target.legalForm" [name]="'companyLegalForm' + i">
                    </mat-form-field>
                    <mat-form-field appearance="outline" class="w-full">
                      <mat-label>Status</mat-label>
                      <input matInput [(ngModel)]="company.comment.target.status" [name]="'companyStatus' + i">
                    </mat-form-field>
                    <mat-form-field appearance="outline" class="w-full">
                      <mat-label>Comment</mat-label>
                      <textarea matInput rows="5" [(ngModel)]="company.comment.target.comment" [name]="'companyScreeningComment' + i"></textarea>
                    </mat-form-field>

                    <h4>Sanctions</h4>
                    <div *ngFor="let sanction of company.comment.target.sanctions; let j = index">
                      <mat-form-field appearance="outline" class="w-full">
                        <mat-label>Authority</mat-label>
                        <input matInput [(ngModel)]="sanction.authority" [name]="'sanctionAuthority' + i + j">
                      </mat-form-field>
                      <mat-form-field appearance="outline" class="w-full">
                        <mat-label>Program</mat-label>
                        <input matInput [(ngModel)]="sanction.program" [name]="'sanctionProgram' + i + j">
                      </mat-form-field>
                      <mat-form-field appearance="outline" class="w-full">
                        <mat-label>Start Date</mat-label>
                        <input matInput [(ngModel)]="sanction.start_date" [name]="'sanctionStartDate' + i + j">
                      </mat-form-field>
                      <mat-form-field appearance="outline" class="w-full">
                        <mat-label>End Date</mat-label>
                        <input matInput [(ngModel)]="sanction.end_date" [name]="'sanctionEndDate' + i + j">
                      </mat-form-field>
                    </div>
                  </mat-card-content>
                </mat-card>
              </div>
            </div>

            <div *ngIf="diligence.sancIssueSummary?.screening?.individuals.length">
              <h3>Individuals</h3>
              <div *ngFor="let individual of diligence.sancIssueSummary.screening.individuals; let i = index">
                <mat-form-field appearance="outline" class="w-full">
                  <mat-label>Individual {{ i + 1 }} Comment</mat-label>
                  <textarea matInput rows="5" [(ngModel)]="individual.comment" [name]="'individualScreeningComment' + i"></textarea>
                </mat-form-field>
              </div>
            </div>
          </mat-expansion-panel>

          <button mat-raised-button color="warn" type="submit">
            Update Diligence Report
          </button>
        </form>
      </mat-card-content>
    </mat-card>
  `,
  styles: [`
    :host {
      display: block;
      padding: 20px;
    }
  `]
})
export class ModifyDiligenceComponent {
  @Input() diligence: any;
  @Output() updateDiligence = new EventEmitter<any>();

  onUpdateDiligence() {
    this.diligence.sancIssueSummary = JSON.stringify(this.diligence.sancIssueSummary);
    this.updateDiligence.emit(this.diligence);
  }
}