<div class="h-full flex items-center">
    <div class="max-w-2xl mx-auto p-6 bg-white rounded-lg shadow-lg h-max">
        <div class="text-3xl text-center mb-4 text-lg">
            Our advanced AI can assist you with a thorough search. Please complete the form below to initiate a detailed
            investigation.
        </div>
        <div class="text-sm text-orange-500 text-center bg-gray-100 rounded p-2 ">
            Please Review Name
            Entries Carefully. The name you enter in the search field will be queried exactly as
            provided. Please double-check the
            accuracy of the name to ensure the most relevant search results.
        </div>

        <mat-tab-group [(selectedIndex)]="selectedTabIndex" (selectedIndexChange)="onTabChange($event)" class="mb-6">
            <mat-tab label="Company">
                <form [formGroup]="companyForm" (ngSubmit)="onSubmitCompany()" class="space-y-4 mt-4">
                    <mat-form-field appearance="outline" class="w-full mb-4">
                        <mat-label>Company Name</mat-label>
                        <input matInput formControlName="companyName" required>
                        <mat-error *ngIf="companyForm.get('companyName')?.hasError('required')">Company name is
                            required</mat-error>
                    </mat-form-field>


                    <mat-select-country appearance="outline" formControlName="country" class="w-full" placeHolder="Country*">
                    </mat-select-country>

                    <mat-form-field appearance="outline" class="w-full">
                        <mat-label>Address</mat-label>
                        <input matInput formControlName="address">
                    </mat-form-field>

                    <mat-form-field appearance="outline" class="w-full">
                        <mat-label>Company Size</mat-label>
                        <mat-select formControlName="companySize" required>
                            <mat-option value="Small">Small</mat-option>
                            <mat-option value="Medium">Medium</mat-option>
                            <mat-option value="Large">Large</mat-option>
                            <mat-option value="Very Large">Very Large</mat-option>
                        </mat-select>
                        <mat-error *ngIf="companyForm.get('companySize')?.hasError('required')">Company size is
                            required</mat-error>
                    </mat-form-field>

                    <mat-form-field appearance="outline" class="w-full">
                        <mat-label>Market</mat-label>
                        <mat-select formControlName="market" required>
                            <mat-option *ngFor="let market of markets" [value]="market">{{ market }}</mat-option>
                        </mat-select>
                        <mat-error *ngIf="companyForm.get('market')?.hasError('required')">Market is
                            required</mat-error>
                    </mat-form-field>

                    <div class="flex justify-between space-x-4 mt-4">
                        <a type="button" class="underline cursor-pointer p-4 text-gray-700" (click)="resetCompany()">Reset
                            Form</a>
                        <button mat-raised-button color="primary" type="submit"
                            [disabled]="companyForm.invalid">Continue</button>
                    </div>
                </form>
            </mat-tab>
            <mat-tab label="Individual">
                <form [formGroup]="individualForm" (ngSubmit)="onSubmitIndividual()" class="space-y-4 mt-4">
                    <mat-form-field appearance="outline" class="w-full mb-4">
                        <mat-label>Full Name</mat-label>
                        <input matInput formControlName="fullName" required>
                        <mat-error *ngIf="individualForm.get('fullName')?.hasError('required')">Full name is
                            required</mat-error>
                    </mat-form-field>

                    <mat-select-country appearance="outline" formControlName="country" class="w-full" placeHolder="Country*">
                    </mat-select-country>

                    <mat-form-field appearance="outline" class="w-full">
                        <mat-label>Address</mat-label>
                        <input matInput formControlName="address">
                    </mat-form-field>

                    <div class="flex justify-between space-x-4 mt-auto">
                        <button mat-stroked-button color="warn" type="button" (click)="resetIndividual()">Reset
                            form</button>
                        <button mat-raised-button color="primary" type="submit"
                            [disabled]="individualForm.invalid">Continue</button>
                    </div>
                </form>
            </mat-tab>

        </mat-tab-group>
    </div>
</div>