import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReportFieldDirective } from 'src/app/core/directives/report-field.directive';
import { SanctionsComponent } from '../sanctions/sanctions.component';


@Component({
  selector: 'app-sanctions-summary',
  standalone: true,
  imports: [
    CommonModule,
    SanctionsComponent,
    ReportFieldDirective
  ],
  styles: [`
    :host {
      display: block;
      page-break-inside: avoid;
    }
    @media print {
      .page-break-avoid {
        page-break-inside: avoid;
      }
    }
  `],
  template: `
    <div class="flex w-full flex-wrap my-5">
      <div class="w-full mb-4">
      <div class="border border-grey border-t-cyan-700 w-full">
        <table class="w-full">
          <thead>
            <tr class="bg-blue-950">
              <th colspan="2" class="text-center">
                <h1 class="text-lg p-1 text-white m-0">Key Information</h1>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr *reportField="'generalInfo.companyName'; let companyName">
              <td class="py-2 px-4 font-bold">Company Name:</td>
              <td class="py-2 px-4">{{ companyName }}</td>
            </tr>
            <tr *reportField="'generalInfo.monitoredEntities'; let monitoredEntities">
              <td class="py-2 px-4 font-bold">Monitored Entities:</td>
              <td class="py-2 px-4">{{ monitoredEntities.join(', ') }}</td>
            </tr>
            <tr *reportField="'generalInfo.vatNumber', let vatNumber">
              <td class="py-2 px-4 font-bold">VAT Number:</td>
              <td class="py-2 px-4">{{ vatNumber }}</td>
            </tr>
            <tr *reportField="'generalInfo.registrationNumber'; let registrationNumber">
                    <td class="py-2 px-4 font-bold">Registration Number:</td>
                    <td class="py-2 px-4">{{ registrationNumber }}
                    </td>
              </tr>
              <tr *reportField="'generalInfo.incorporationDate'; let incorporationDate">
                  <td class="py-2 px-4 font-bold">Incorporation Date:</td>
                  <td class="py-2 px-4">{{ incorporationDate |
                      date }}</td>
              </tr>
              <tr *reportField="'generalInfo.legalForm'; let legalForm">
                  <td class="py-2 px-4 font-bold">Legal Form:</td>
                  <td class="py-2 px-4">{{ legalForm }}</td>
              </tr>
              <tr *reportField="'generalInfo.taxCode'; let taxCode">
                  <td class="py-2 px-4 font-bold">Tax Code:</td>
                  <td class="py-2 px-4">{{ taxCode }}</td>
              </tr>
              <tr *reportField="'generalInfo.otherLegalName'; let value">
                  <td class="py-2 px-4 font-bold">Other Legal Name:</td>
                  <td class="py-2 px-4">{{
                    value
                      }}</td>
              </tr>
              <tr *reportField="'generalInfo.charterNumber'; let value">
                  <td class="py-2 px-4 font-bold">Charter Number:</td>
                  <td class="py-2 px-4">{{
                    value
                      }}</td>
              </tr>
              <tr *reportField="'generalInfo.establishmentDate'; let value">
                  <td class="py-2 px-4 font-bold">Establishment Date:</td>
                  <td class="py-2 px-4">{{
                    value
                      | date }}</td>
              </tr>
              <tr
                  *reportField="'generalInfo.companyType'; let value">
                  <td class="py-2 px-4 font-bold">Company Type:</td>
                  <td class="py-2 px-4">{{
                    value }}
                  </td>
              </tr>
              <tr *reportField="'generalInfo.sicDescription'; let value">
                  <td class="py-2 px-4 font-bold">SIC Description:</td>
                  <td class="py-2 px-4">{{
                    value
                      }}</td>
              </tr>
          </tbody>
        </table>
      </div>
      </div>
      <div class="w-full border-1 border-grey" *reportField="'sanctions.summary'; let sanctionsData">
          
      <div>
      <!-- Relations with Sanctioned Entities Section -->
      <div class="mb-8 border border-grey border-t-cyan-700 w-full">
        <div class="bg-blue-950 text-center text-white">
          <h1 class="text-lg p-1 text-white m-0">Santions Summary</h1>
        </div>
        <div class="flex text-center">
        <div class="bg-white border overflow-hidden m-2 w-1/2 border-t-cyan-700">
          <p class="text-sm text-gray-700 pt-[12px]"><span class="font-semibold">Sanctioned Entity:</span> {{ sanctionsData.sanctionedEntity }}</p>
        </div>
        <div class="bg-white border overflow-hidden m-2 w-1/2 border-t-cyan-700">
          <p class="text-sm text-gray-700 pt-[12px]"><span class="font-semibold">Key Points:</span> {{ sanctionsData.keyPoints }}</p>
        </div>
        </div>
        <div class="bg-bgColor text-center">
          <h3 class="w-full m-0 p-2 text-xl">Relations with Sanctioned Hostile and/or Malevolent Entities</h3>
        </div>
        <div class="bg-white shadow overflow-hidden">
          <table class="min-w-full divide-y divide-gray-200">
            <thead class="bg-gray-50">
              <tr>
                <th class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Entity</th>
                <th class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Result</th>
                <th class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Reason</th>
              </tr>
            </thead>
            <tbody class="bg-white divide-y divide-gray-200">
              <tr *ngFor="let entity of sanctionsData.relationsWithSanctionedHostileAndOrMalevolentEntities">
                <td class="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">{{ entity.row }}</td>
                <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{{ entity.result }}</td>
                <td class="px-6 py-4 text-sm text-gray-500">{{ entity.reason }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>

      <!-- Sanction Details Section -->
      <div class="mb-8 border border-grey border-t-cyan-700 w-full">
        <div class="bg-blue-950 text-white text-center">
          <h1 class="text-lg p-1 text-white m-0">Sanction Details</h1>
        </div>
        <div class="bg-white shadow overflow-hidden ">
          <table class="min-w-full divide-y divide-gray-200">
            <thead class="bg-gray-50">
              <tr>
                <th class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Authority</th>
                <th class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Program</th>
                <th class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Start Date</th>
                <th class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">End Date</th>
              </tr>
            </thead>
            <tbody class="bg-white divide-y divide-gray-200">
              <tr *ngFor="let detail of sanctionsData.sanctionDetails">
                <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{{ detail.authority }}</td>
                <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{{ detail.program }}</td>
                <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{{ detail.startDate }}</td>
                <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{{ detail.endDate }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
      </div>
    </div>
  `
})
export class SanctionsSummaryComponent {
  @Input() printMode: boolean = false;
}