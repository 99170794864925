import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';

import { enableProdMode, isDevMode } from "@angular/core";
import * as Sentry from "@sentry/angular";
import { environment } from './environments/environment';

if (!isDevMode()) {
  enableProdMode();

  Sentry.init({
    dsn: "https://663819ed2e904b2426dddfc7a70932ac@o4507919649603584.ingest.us.sentry.io/4507919651962880",
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.replayIntegration(),
      Sentry.feedbackIntegration({
        colorScheme: "system",
      }),
    ],
    environment: environment.environment,
    // Tracing
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: [environment.baseUrl],
    // Session Replay
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0
  });
}


platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch((err) => console.error(err));