import { Component, OnInit, inject } from '@angular/core';
import { NavbarComponent } from "../../shared/components/navbar/navbar.component";
import { AuthService } from '@auth0/auth0-angular';
import { CommonModule } from '@angular/common';
import { MatTabsModule } from '@angular/material/tabs';
import { MatChipsModule } from '@angular/material/chips';
import { ApiService } from 'src/app/core/services/api.service';
import { switchMap } from 'rxjs';
import { MatTableModule } from '@angular/material/table';
import { MatIconModule } from '@angular/material/icon';
import { Router } from '@angular/router';
import { FooterComponent } from "../../shared/components/footer/footer.component";
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { packages } from 'src/app/core/models/packages';

@Component({
    selector: 'app-my-account',
    templateUrl: './my-account.component.html',
    styleUrls: ['./my-account.component.scss'],
    standalone: true,
    imports: [NavbarComponent, CommonModule, MatTabsModule, MatTableModule, MatIconModule, FooterComponent, MatProgressSpinnerModule, MatChipsModule]
})
export class MyAccountComponent implements OnInit {
    auth = inject(AuthService);
    apiService = inject(ApiService);
    router = inject(Router);
    packageChosen = {};

    displayedColumns: string[] = ['Email', 'Type', 'Name', 'Report', 'CreateDate', 'Score', 'Actions'];

    diligences = [];
    loading = false;

    ngOnInit(): void {
        this.loading = true;
        this.auth.user$.pipe(
            switchMap(user => {
                if (user?.email) {
                    return this.apiService.getDiligencesByEmail();
                }
                else {
                    //TODO: handle error
                    throw new Error('user not logged in');
                }
            })
        ).subscribe(
            diligences => {
                this.loading = false;
                this.diligences = diligences?.dueDiligences
            }
        )
    }

    getDiligenceName(diligence:any) {
        const packageChosen = packages.find(p => p.code == diligence?.report)
        return packageChosen?.name;
    }

    openDiligence(uuid: string) {
        this.router.navigate(['/dueDiligence', uuid]);
    }
}
