import { Injectable, inject } from '@angular/core';
import { AuthService } from '@auth0/auth0-angular';
import { Observable, map, switchMap, of, tap } from 'rxjs';
import { GoogleAnalyticsService } from './google-analytics.service';
import * as Sentry from "@sentry/angular";

interface DecodedToken {
  permissions?: string[];
  [key: string]: unknown;
}

@Injectable({
  providedIn: 'root'
})
export class MyAuthService {
  private auth = inject(AuthService);
  private gaService = inject(GoogleAnalyticsService);

  constructor() {
    this.setupUserTracking();
  }

  private setupUserTracking() {
    this.auth.user$.pipe(
      tap(user => {
        if (user && user.sub) {
          // Google Analytics tracking
          this.gaService.setUserId(user.sub);
          this.gaService.setUserProperties(user.email || '');

          // Sentry user tracking
          Sentry.setUser({
            id: user.sub,
            email: user.email,
            username: user.name
          });
        } else {
          // Clear Sentry user when not authenticated
          Sentry.setUser(null);
        }
      })
    ).subscribe();
  }

  getPermissions(): Observable<string[]> {
    return this.auth.getAccessTokenSilently().pipe(
      switchMap(token => {
        const decodedToken = this.decodeToken(token);
        return of(decodedToken?.permissions || []);
      })
    );
  }

  isAdmin(): Observable<boolean> {
    return this.getPermissions().pipe(
      map(permissions => {
        return permissions.includes('admin:everything');
      })
    );
  }

  private decodeToken(token: string): DecodedToken | null {
    try {
      return JSON.parse(atob(token.split('.')[1])) as DecodedToken;
    } catch (e) {
      return null;
    }
  }
}